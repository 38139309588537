import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '@/shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from '@/shared/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitted: boolean = false;
  emailToken: any;
  email: any;
  fieldTextType: boolean;
  fieldTextType2: boolean;
  resetForm = this.formBuilder.group({
      Password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d.*)(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$')]],
      ConfirmPassword: ['', [Validators.required]],
      Email: '',
      EmailToken: ''
    },
    {
      validator: this.commonService.matchingPasswords('Password', 'ConfirmPassword')
    });

  constructor(
    private title: Title,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService,
  ) {
    this.title.setTitle('Compass Care | Reset Password');
    this.route.params.subscribe( params => {
      this.email = params.Email
      this.emailToken = params.EmailToken
    });
    //this.route.params.subscribe( params => this.emailToken = params.EmailToken );

    this.resetForm.patchValue({Email:this.email});
    this.resetForm.patchValue({EmailToken:this.emailToken});
  }

  ngOnInit(): void {
    //console.log(this.emailToken, this.emailToken);
  }

  resetPass(){
    this.submitted = true;
    if(this.resetForm.valid){
      this.apiService.postRequest('Auth/password/reset', this.resetForm.value).subscribe((res) => {
        if( res['status'] ){
          this.toastr.success(res['message']);
          this.router.navigate(['/login']);
        }
        if( res['status'] === false ){
          this.toastr.error( res['message'] );
        }
      });
    }
  }

  showPassword(type) {
    if (type == 1)
      this.fieldTextType = !this.fieldTextType;
    else
      this.fieldTextType2 = !this.fieldTextType2;
  }
}
