import { ApiService } from '@/shared/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verification-successful',
  templateUrl: './verification-successful.component.html',
  styleUrls: ['./verification-successful.component.scss']
})
export class VerificationSuccessfulComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }
  loadPage = false;
  // -1 means verifying
  emailVerificationStatus = -1;

  ngOnInit(): void {
    this.loadPage = false;
    this.verifyEmail();
  }

  verifyEmail() {
    const verificationToken = this.route.snapshot.paramMap.get('verificationToken');
    this.apiService.postRequest('user/signupemailverification', {"Token": verificationToken}).subscribe(
      resData => {
        if (resData.status) {
          // 1 means verified
          this.emailVerificationStatus = 1;
        } else {
          // 1 means not-verified
          this.emailVerificationStatus = 0;
        }
        this.loadPage = true;
      },
      err => {
        if (err.error && err.error.message === 'User already verified!') {
          // 2 already verified
          this.emailVerificationStatus = 2;
        } else{
          this.emailVerificationStatus = 0;
        }
        this.loadPage = true;
        
      }
    );

  }

}
