import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { StorageService } from '@/shared/services/storage.service';
import { ApiService } from '@/shared/services/api.service';
import { SignalRService } from '@/shared/services/signal-r.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {

  constructor(
    public router: Router,
    private apiService: ApiService,
    private storageService: StorageService,
    private signalRService: SignalRService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.SignOut();
  }

  SignOut() {
    this.apiService.getRequest('Auth/logout').subscribe(res =>{
      if(res.status ==true){
        this.storageService.clearStorage();
        this.storageService.remove('user');
        this.signalRService.closeConnection();
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(res['message']);
      }
    });

  }
}
