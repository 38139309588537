import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if( localStorage.getItem('role') !== 'admin' && ! ((state.url.includes('profile')) || (state.url.includes('preliminary')))) {
      // alert('Unauthorized Access');
      this.router.navigate(['/unauthorized-access']);
      return false;
    }

    return true;
  }
  
}
