import { CommonService } from '@/shared/services';
import { ApiService } from '@/shared/services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  signUpForm: FormGroup;

  signUpTypes: any = [];
  loadPage = false;
  passwordFieldTextType: boolean = false;
  confirmPasswordFieldTextType: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getSignUpTypes()
    this.initLoginForm()
  }

  /*
    Getting the form controls
  */
  get controls() { return this.signUpForm.controls; }

  initLoginForm() {
    this.signUpForm = this.formBuilder.group(
      {
        fname: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')]],
        lname: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')]],
        phone: ['', [Validators.required, Validators.pattern('^\\d{3}-\\d{3}-\\d{4}$')]],
        email: ['', [Validators.required, Validators.email]],
        signUpBy: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
        confirmPassword: ['', [Validators.required]],
      },{
        validator: this.commonService.matchingPasswords('password', 'confirmPassword')
      }
    );
  }

  onSubmit() {
    this.OnsignUp();
  }

  OnsignUp () {
    this.loadPage = false;
    const signupParams = this.signUpForm.value;
    signupParams.signupTypeID = parseInt(signupParams.signUpBy);
    signupParams.firstName = signupParams.fname;
    signupParams.lastName = signupParams.lname;
    delete signupParams.signUpBy;

    this.apiService.postRequest('user/signup', signupParams).subscribe(
      resData => {
        if (resData.status) {
          this.router.navigateByUrl('/verification');
        }
        this.loadPage = true;
      },
      err => {
        this.loadPage = true;
      }
    );
  }
  getSignUpTypes(){
    this.loadPage = false;
    this.apiService.postRequest('masterlist', {
      "Type": "SIGNUPTYPE"
    }).subscribe(
      resData => {
        this.signUpTypes = [];
        if (resData.status) {
          resData.data.forEach(signupType => {
            this.signUpTypes.push(signupType)
          });
        }
        this.loadPage = true;
      },
      err => {
        this.loadPage = true;
      }
    );
  }
}
