import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
const baseURL = environment.api_url;
// const httpOptions = {
//   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
// const self = this;


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private handleError(error: any): Promise<any> {
    //console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
 }
  token = localStorage.getItem('token') == null ? '' : localStorage.getItem('token');
  config = {
    headers: {
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    }
  };
  baseURL = environment.api_url;
  
  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    
  ) { }



  getRequest(url): Observable<any> {
    return this.httpClient.get(baseURL + url).pipe(map((response) => {
      this.spinner.hide();
      return response;
    }));
  }

  postRequest(url,data, options?): Observable<any> {
    this.spinner.show();
    return this.httpClient.post(baseURL + url, data, options).pipe(map((response) => {
      this.spinner.hide();
      return response;
    }));
  }

  updateRequest(url, data): Observable<any> {
    return this.httpClient.put(baseURL + url, data).pipe(map((response) => {
      this.spinner.hide();
      return response;
    }));
  }

  deleteRequest(url,data): Observable<any> {
    const options = {
      headers: new HttpHeaders(this.config.headers),
      body: data
    }
    return this.httpClient.delete(baseURL + url, options).pipe(map((response) => {
      this.spinner.hide();
      return response;

    }));
  }

  xyz(api_url, data, type = null) {
    return new Promise((resolve, reject) => {
      this.config.headers.Authorization = localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token');
      this.config.headers['Content-Type'] = type ? 'application/x-www-form-urlencoded' : 'application/json';

      // return this.http.post<Hero>(this.heroesUrl, hero, httpOptions)
      //   .pipe(
      //     catchError(this.handleError('addHero', hero))
      //   );
      this.httpClient.post(this.baseURL + api_url, data, this.config).subscribe((data): any => {
        resolve(data);
      }, err => {
        reject(this.handleError(err));
      });
    });
  }
  
}
