import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../core/role';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  firstName = localStorage.getItem('firstName');
  lastName = localStorage.getItem('lastName');
  invitedCaregiver = parseInt(localStorage.getItem('invitedCaregiver'));
  public role = Role;
  selectedRole: String;
  buttonDisabled = true;
  constructor(private route: Router)
  {
    console.log(this.invitedCaregiver);
  }

  ngOnInit(): void {
    localStorage.setItem('isLoggedIn', 'false');
  }

  ngOnDestroy(){
    localStorage.removeItem('invitedCaregiver');
  }

  onSelectRole(roleType: String) {
    this.buttonDisabled = false;
    this.selectedRole = roleType;
  }

  onSubmitRoleSelection(){
    localStorage.setItem('isLoggedIn', 'true');
    
    if (this.selectedRole === this.role.Care_Giver) {

      localStorage.setItem('role', "caregiver");
      this.route.navigate(['/profile-care-giver/auth/user-details']);

    } else if (this.selectedRole === this.role.Care_Recipient) {
      
      localStorage.setItem('role', "carerecipient");
      this.route.navigate(['/profile-care-recipient/auth/user-details']);

    } else if (this.selectedRole === this.role.Health_Care_Provider) {

      localStorage.setItem('role', "healthcareprovider");
      this.route.navigate(['/profile-healthcare-provider/auth/user-details']);
    }
  }

}
