import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard, LoginGuard, AdminGuard, AccessGuard } from '@/shared';
import {LoginComponent} from '@/auth/login/login.component';
import {ForgotPasswordComponent} from '@/auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from '@/auth/reset-password/reset-password.component';
import { SignUpComponent } from '@/auth/sign-up/sign-up.component';
import { VerificationComponent } from './auth/verification/verification.component';
import { RoleComponent } from './auth/role/role.component';
import { VerificationSuccessfulComponent } from './auth/verification-successful/verification-successful.component';
import { UnauthorizedAccessComponent } from './static-pages/unauthorized-access/unauthorized-access.component';
import {EmergencyDetailsQrComponent} from '@/emergency-details-qr/emergency-details-qr.component';
import { Roles } from './shared/modules/roles';
import { LogoutComponent } from './layout/components/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: {
        label: 'Dashboard',
      }
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'verification',
    component: VerificationComponent,
    //canActivate: [LoginGuard]
  },
  {
    path: 'role',
    component: RoleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'reset-password/:Email/:EmailToken',
    component: ResetPasswordComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'verification-successful/:verificationToken',
    component: VerificationSuccessfulComponent,
    //canActivate: [LoginGuard]
  },
  {
    path: 'profile-care-giver',
    loadChildren: () => import('./profile-caregivers/profile-caregivers.module').then((m) => m.ProfileCaregiversModule),
    canActivate: [AuthGuard, AccessGuard], data: { roles: [Roles.Caregiver, Roles.Admin] }
  },
  {
    path: 'profile-care-recipient',
    loadChildren: () => import('./profile-care-recipient/profile-care-recipient.module').then((m) => m.ProfileCareRecipientModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-healthcare-provider',
    loadChildren: () => import('./profile-healthcare-provider/profile-healthcare-provider.module').then((m) => m.ProfileHealthcareProviderModule),
    canActivate: [AuthGuard, AccessGuard], data: { roles: [Roles.HealthCareProvider, Roles.Admin] }
  },
  {
    path: 'error',
    loadChildren: () => import('./server-error/server-error.module').then((m) => m.ServerErrorModule)
  },
  {
    path: 'access-denied',
    loadChildren: () => import('./access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
  },
  { path: 'unauthorized-access',   component: UnauthorizedAccessComponent},

  {
    path:'emergency-details/:id',
    loadChildren: () => import('./emergency-details-qr/emergency-details-qr.module').then((m) => m.EmergencyDetailsQrModule)

  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard]
  },
  { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule) },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
