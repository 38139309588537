import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ssnMask]',
})
export class SsnNumberMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }
  

  onInputChange(event, backspace) {
    if (event) {
      let ssnNumber = event.replace(/\D/g, '');
      if (backspace && ssnNumber.length <= 5) {
        ssnNumber = ssnNumber.substring(0, ssnNumber.length - 1);
      }
      if (ssnNumber.length === 0) {
        ssnNumber = '';
      } else if (ssnNumber.length <= 3) {
        ssnNumber = ssnNumber.replace(/^(\d{0,3})/, '$1-');
      } else if (ssnNumber.length <= 5) {
        ssnNumber = ssnNumber.replace(/^(\d{0,3})(\d{0,2})/, '$1-$2-');
      } else {
        ssnNumber = ssnNumber.substring(0,9);
        ssnNumber = ssnNumber.replace(/^(\d{0,3})(\d{0,2})(\d{0,4})/,'$1-$2-$3');
      }

      if (ssnNumber === '') {
        this.ngControl.control.patchValue('');
      } else {
        this.ngControl.valueAccessor.writeValue(ssnNumber);
      }
    }
    
  }
}
