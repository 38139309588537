import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { ApiService } from '@/shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import { MouseEvent, AgmMap ,AgmMarker } from '@agm/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@/shared/services/storage.service';
import { Roles } from '@/shared/modules/roles';
import {CommonService} from '@/shared/services';
import {ToastrService} from 'ngx-toastr';
import LatLngBounds = google.maps.LatLngBounds;
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-googlemaps',
  templateUrl: './googlemaps.component.html',
  styleUrls: ['./googlemaps.component.scss']
})
export class GooglemapsComponent implements OnInit {
  @ViewChild(AgmMap) public agmMap: AgmMap
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild('addressInput')
  addressInput: ElementRef;
  placesTypes=[{name: 'Accounting', code: 'accounting'},
    {name: 'Airport', code: 'airport'},
    {name: 'Amusement Park', code: 'amusement_park'},
    {name: 'Aquarium', code: 'aquarium'},
    {name: 'Art Gallery', code: 'art_gallery'},
    {name: 'ATM', code: 'atm'},
    {name: 'Bakery', code: 'bakery'},
    {name: 'Bank', code: 'bank'},
    {name: 'Bar', code: 'bar'},
    {name: 'Beauty Salon', code: 'beauty_salon'},
    {name: 'Bicycle Store', code: 'bicycle_store'},
    {name: 'Book Store', code: 'book_store'},
    {name: 'Bowling Alley', code: 'bowling_alley'},
    {name: 'Bus Station', code: 'bus_station'},
    {name: 'Cafe', code: 'cafe'},
    {name: 'Camp Ground', code: 'campground'},
    {name: 'Car Dealer', code: 'car_dealer'},
    {name: 'Car Rental', code: 'car_rental'},
    {name: 'Car Repair', code: 'car_repair'},
    {name: 'Car Wash', code: 'car_wash'},
    {name: 'Casino', code: 'casino'},
    {name: 'Cemetry', code: 'cemetery'},
    {name: 'Church', code: 'church'},
    {name: 'City Hall', code: 'city_hall'},
    {name: 'Clothing Store', code: 'clothing_store'},
    {name: 'Convenience Store', code: 'convenience_store'},
    {name: 'CourtHouse', code: 'courthouse'},
    {name: 'Dentist', code: 'dentist'},
    {name: 'Department Store', code: 'department_store'},
    {name: 'Doctor', code: 'doctor'},
    {name: 'Electrician', code: 'electrician'},
    {name: 'Electronics Store', code: 'electronics_store'},
    {name: 'Embassy', code: 'embassy'},
    {name: 'Establishment', code: 'establishment'},
    {name: 'Finance', code: 'finance'},
    {name: 'Fire Station', code: 'fire_station'},
    {name: 'Florist', code: 'florist'},
    {name: 'Food', code: 'food'},
    {name: 'Funeral Home', code: 'funeral_home'},
    {name: 'Furniture Store', code: 'furniture_store'},
    {name: 'Gas Station', code: 'gas_station'},
    {name: 'General Contractor', code: 'general_contractor'},
    {name: 'Grocery OR SuperMarket', code: 'grocery_or_supermarket'},
    {name: 'Gym', code: 'gym'},
    {name: 'Hair Care', code: 'hair_care'},
    {name: 'HardWare Store', code: 'hardware_store'},
    {name: 'Health', code: 'health'},
    {name: 'Hindu Temple', code: 'hindu_temple'},
    {name: 'Home Goods Store', code: 'home_goods_store'},
    {name: 'Hospital', code: 'hospital'},
    {name: 'Insurance Agency', code: 'insurance_agency'},
    {name: 'Jewellary Store', code: 'jewelry_store'},
    {name: 'Laundry', code: 'laundry'},
    {name: 'Lawyer', code: 'lawyer'},
    {name: 'Library', code: 'library'},
    {name: 'Liquor Store', code: 'liquor_store'},
    {name: 'Local Government Office', code: 'local_government_office'},
    {name: 'LockSmith', code: 'locksmith'},
    {name: 'Lodging', code: 'lodging'},
    {name: 'Meal Delivery', code: 'meal_delivery'},
    {name: 'Meal TakeAway', code: 'meal_takeaway'},
    {name: 'Mosque', code: 'mosque'},
    {name: 'Movie Rental', code: 'movie_rental'},
    {name: 'Movie Theater', code: 'movie_theater'},
    {name: 'Moving Company', code: 'moving_company'},
    {name: 'Museum', code: 'museum'},
    {name: 'Night Club', code: 'night_club'},
    {name: 'Painter', code: 'painter'},
    {name: 'Park', code: 'park'},
    {name: 'Parking', code: 'parking'},
    {name: 'Pet Store', code: 'pet_store'},
    {name: 'Pharmacy', code: 'pharmacy'},
    {name: 'PhysioTherapist', code: 'physiotherapist'},
    {name: 'Place Of Worship', code: 'place_of_worship'},
    {name: 'Plumber', code: 'plumber'},
    {name: 'Police', code: 'police'},
    {name: 'Post Office', code: 'post_office'},
    {name: 'Real Estate Agency', code: 'real_estate_agency'},
    {name: 'Restaurent', code: 'restaurant'},
    {name: 'Roofing Contractor', code: 'roofing_contractor'},
    {name: 'RV park', code: 'rv_park'},
    {name: 'School', code: 'school'},
    {name: 'Shoe Store', code: 'shoe_store'},
    {name: 'Shopping Mall', code: 'shopping_mall'},
    {name: 'Spa', code: 'spa'},
    {name: 'Stadium', code: 'stadium'},
    {name: 'Storage', code: 'storage'},
    {name: 'Store', code: 'store'},
    {name: 'SubWay Station', code: 'subway_station'},
    {name: 'Synagogue', code: 'synagogue'},
    {name: 'Taxi Stand', code: 'taxi_stand'},
    {name: 'Train Station', code: 'train_station'},
    {name: 'Travel Agency', code: 'travel_agency'},
    {name: 'University', code: 'university'},
    {name: 'Veterinary Care', code: 'veterinary_care'},
    {name: 'Zoo', code: 'zoo'},
  ];
  searchTypes= [
    {id:'Geriatric Care Manager near me',value:'Geriatric Care Manager'},
    {id:'Home Caregiver near me',value:'Home Caregiver'},
    {id:'Home Care Company/Agency near me',value:'Home Care Company/Agency'},
    {id:' Assisted Living Facility near me',value:' Assisted Living Facility'},
    {id:'Nursing Home near me',value:'Nursing Home'},
    {id:'Trust & Estate Attorney near me',value:'Trust & Estate Attorney'},
    {id:'OTHER',value:'OTHER'},
  ];
  calRadius:any
  RadiusLat:any
  RadiusLong:any
  zoom: number = 12;
  latt:any
  lngg:any
  key:any
  getData: any = [];
  myProfileForm: FormGroup;
  title = 'rou';
  map: any;
  options={
    componentRestrictions:{
      country:["IN"]
    }
  };
  markers: any[] = []
  infoWindowOpened = null;
  previous_info_window = null;
  moreButton;
  getNextPage: () => void | false;
  hasNextPage= false;
  submitted= false;
  previousSearch:any;
  previousRadius:any;
  constructor(private httpClient: HttpClient, private formBuilder: FormBuilder,private apiService: ApiService,private router: ActivatedRoute,private storageService:StorageService,
              private route:Router,
              private modalService:NgbModal,
              private toastrService: ToastrService,
              public commonService :CommonService) {



  }

  ngOnInit(): void {
    this.myProfileForm = this.formBuilder.group(
      {

        address: [{value:'', disabled: true}, [Validators.required]],
        searchType: [null, [Validators.required]],
        searchText: [''],
        radius: [null, [Validators.required]],


      }
    );

    this.myProfileForm.controls.searchType.valueChanges.subscribe(res=>{

      if(res == 'OTHER'){
          this.myProfileForm.controls.searchText.setValidators([Validators.required,Validators.maxLength(150),Validators.pattern(this.commonService.addressPattern), this.commonService.noWhitespaceValidator])
      }else{
        this.myProfileForm.controls.searchText.setValidators(null);
      }
      this.myProfileForm.controls.searchText.updateValueAndValidity();

      console.log(this.myProfileForm.controls.searchText.validator);
    })


    //this.checkingtype=this.storageService.get('address')
    this.router.params.subscribe( params => {
      if(params && params.id)
        this.getCareRecipientDetail(params.id);
      else
        this.getCareRecipientDetail(null);
    });

  }

  ngAfterViewInit(){
    this.moreButton= document.getElementById("more") as HTMLButtonElement;
    console.log(this.moreButton);
  }
  // addressInput=this.storageService.get('address')
  public handleAddressChange(address:any) {

    this.latt=address.geometry.location.lat()
    this.lngg=address.geometry.location.lng()
    //setting address from API to local variable
    //this.formattedaddress=this.checkingtype.formatted_address
  }


  setCurrentLocation() {
    if ('geolocation' in navigator) {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
      navigator.geolocation.getCurrentPosition((position) => {

        let self = this;

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode( { 'location': {lat:position.coords.latitude,lng:position.coords.longitude}}, function(results, status) {

          if (status == google.maps.GeocoderStatus.OK) {
            self.latt= results[0].geometry.location.lat();
            self.lngg = results[0].geometry.location.lng();
            self.RadiusLat= results[0].geometry.location.lat();
            self.RadiusLong = results[0].geometry.location.lng();
            self.agmMap.triggerResize(true).then(()=>{


            });

          }
        });
          //this.handleAddressChange(position);
       /* setTimeout(()=>{
          this.agmMap.triggerResize(true).then(()=>{
            this.RadiusLat = position.coords.latitude;
            this.RadiusLong = position.coords.longitude;
            this.latt = position.coords.latitude;
            this.lngg = position.coords.longitude;

            console.log(this.latt,this.lngg);

          })
        })*/
      },
        function error(msg) {alert('Please enable your GPS position feature.');},
        options);
    }
  }

  GetLatlong() {
    let self = this;
    var address = this.myProfileForm.getRawValue().address;
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode( { 'address': address}, function(results, status) {

      if (status == google.maps.GeocoderStatus.OK) {
        self.latt= results[0].geometry.location.lat();
        self.lngg = results[0].geometry.location.lng();
        self.RadiusLat= results[0].geometry.location.lat();
        self.RadiusLong = results[0].geometry.location.lng();


      } else {
        console.log("Geocode was not successful for the following reason: " + status);
        self.setCurrentLocation()
      }
    });
  }

  onSearch(){
    this.markers=[];
    this.zoom = 8;
    this.key='AIzaSyDU5gXg1T4iJDTy056PgPCmvkN_06MmiHE'
    this.calRadius=this.myProfileForm.value.radius * 1609.34;
    console.log(this.calRadius);
    //let URL=`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${this.latt},${this.lngg}&radius=${this.calRadius}&type=hospital&keyword=cruise&key=${this.key}`
    this.apiService. postRequest('carerecipient/textsearchget',{
      "Location": this.latt+"%2C"+this.lngg,
      "Radius": parseInt(this.calRadius),
      // "Type": this.myProfileForm.value.types.join('|'),
      "Query": 'Hospitals near me'
    }).subscribe(res=>{
      this.getData=res.data;

      this.zoom = 12

      this.getData.forEach(item=>{
        this.markers.push({
          lat: item.geometry.location.lat,
          lng: item.geometry.location.lng,
          label:item.name,
          draggable:false
        });
      });

      let data = this.commonService.averageGeolocation(this.markers)
      this.RadiusLat=data.latitude;
      this.RadiusLong=data.longitude;

    })
  }
  get controls() { return this.myProfileForm.controls; }


  clickedMarker(label: string, index: number,infoWindow: any) {
    if (this.previous_info_window == null)
      this.previous_info_window = infoWindow;
    else{
      this.infoWindowOpened = infoWindow
      this.previous_info_window.close()
    }
    this.previous_info_window = infoWindow
  }

  public onMapReady(e) {
    this.map = e;
    setTimeout(()=>{
      this.agmMap.triggerResize(true)
    })
  }

  close_window(e: any){
    if (this.previous_info_window) {
      this.previous_info_window.close()
    }
  }
  onMouseOver(infoWindow, gm) {
    if ( this.previous_info_window!= null) {
      this.previous_info_window.close();
    }
    this.previous_info_window = infoWindow
    infoWindow.open();
  }
  centerChange(event: any) {
    if (event) {
      this.latt = event.lat;
      this.lngg = event.lng;

      setTimeout(()=>{
        this.agmMap.triggerResize(true)
      })
    }
  }

  getCareRecipientDetail(careRecipientId = null){

    let apiSubscription;
    if (careRecipientId) {

      if(localStorage.getItem('role') == Roles.CareRecipient){
        this.route.navigate(['/unauthorized-access']);
      }

      apiSubscription = this.apiService.postRequest('carerecipient/get', {
        CareRecipientID: careRecipientId
      })
    } else {
      apiSubscription =this.apiService.getRequest('carerecipient/carerecipientget')
    }

    apiSubscription.subscribe(
      resData => {
        if (resData['status']) {
          if(!resData.careRecipientDetails.address){
            this.setCurrentLocation()
          }else {
            this.myProfileForm.controls.address.patchValue(resData.careRecipientDetails.address);
            this.GetLatlong()
          }
        }
      }

    );
  }

  searchNearbyLocations(){

    this.submitted = true;
    this.previous_info_window = null;
    if(this.myProfileForm.valid) {
      this.zoom = 8;
      this.calRadius = this.myProfileForm.value.radius * 1609.34;
      let data = this.myProfileForm.value;
      if ((this.previousSearch != data.searchType) || (this.previousRadius != data.radius)) this.markers = [];

      var pyrmont = new google.maps.LatLng(this.latt, this.lngg);
      var request = {
        location: pyrmont,
        radius: this.calRadius,
        keyword: data.searchType == 'OTHER' ? data.searchText : data.searchType,
        distance: 1
      };
      this.previousSearch = request.keyword
      this.previousRadius = request.radius
      var service = new google.maps.places.PlacesService(this.map);

      service.nearbySearch(request, (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus,
        pagination: google.maps.places.PlaceSearchPagination | null
      ) => {
        if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          this.toastrService.error('No results found. Try to increase radius');
          this.zoom = 12
          return
        }
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          console.log(this.calRadius, results);

          this.getData = results;


          this.getData.forEach(item => {
            this.markers.push({
              lat: item.geometry.location.lat(),
              lng: item.geometry.location.lng(),
              label: item.name,
              draggable: false,
              vicinity: item.vicinity,
              name: item.name,
              user_ratings_total: item.user_ratings_total
            });
          });
          setTimeout(()=>{
            this.agmMap.triggerResize(true).then(()=>{
              const bounds: LatLngBounds = new google.maps.LatLngBounds();
              for (const mm of this.markers) {
                bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
              }
              this.map.fitBounds(bounds);

            })
          })
          let data = this.commonService.averageGeolocation(this.markers)
          this.RadiusLat = data.latitude;
          this.RadiusLong = data.longitude;
          /*this.moreButton.disabled = !pagination || !pagination.hasNextPage;
          this.hasNextPage  = pagination.hasNextPage
          if (pagination && pagination.hasNextPage) {
            this.getNextPage = () => {
              // Note: nextPage will call the same handler function as the initial call
              pagination.nextPage();
            };
          }*/

        }

      });
    }
  }

  loadMore(){
    this.moreButton.disabled = true;

    if (this.getNextPage) {
      this.getNextPage();
    }
  }

  close() {
    this.modalService.dismissAll();
  }
}

