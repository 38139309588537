
import {Injectable} from '@angular/core';
import {HttpRequest,HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {StorageService} from '@/shared/services/storage.service';


@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService
  ) {
  }


  /**
   * intercept all XHR request
   * @param request
   * @param next
   * @returns {Observable<A>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * continues request execution
     */

    const userToken = localStorage.getItem('token');
    let authReq;

    //pass auth token
    if(userToken) {
      request = request.clone({
        setHeaders: {'Authorization': `Bearer ${userToken}`},
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !(event.body.status)) {
          if (event.body.message) {
            this.handleError(event.body.message);
          }
        }
      }),

      catchError((error, caught) => {
        this.handleAuthError(error);
        return of(error);
      }) as any
    );
  }

  private handleError(message: string) {
    this.toastr.error(message, 'Error!');
  }


  /**
   * manage-patients errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (!('error' in err && err.error!= null && 'text' in err.error && err.error.text.toLowerCase().includes('pdf'))) {
      let self = this;
      if (err.status === 401) {
        this.toastr.error('Unauthorrized','Error!');
        localStorage.clear();
        this.spinner.hide();
        setTimeout(function () {
          self.router.navigate(['/login']);
        }, 1000);
        return of(err.message);
      }
      this.toastr.error(err.error.message, 'Error!');
    }
    this.spinner.hide();
    throw err;
  }

  private getToken(){
    return localStorage.getItem('token');
  }
}
