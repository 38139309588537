import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {ApiService} from '@/shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {StorageService} from '@/shared/services/storage.service';
import { CommonService } from '@/shared/services/common.service';
import { SignalRService } from '@/shared/services/signal-r.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  fieldTextType: boolean = false;
  loginForm = this.formBuilder.group({
    email : ['',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')
      ]
    ],
    password: ['', Validators.required]
   });

  constructor(
    private signalRService: SignalRService,
    private commonService: CommonService,
    private title: Title,
    private route: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private storageService: StorageService
  ) {
    this.title.setTitle('Compass Care | Login');
  }

  ngOnInit() {}

  onSignIn(){
    this.submitted = true;
    if(this.loginForm.valid){
      this.apiService.postRequest('Auth/Authenticate', this.loginForm.value).subscribe(res =>{
        if(res['status']==true){
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('userId', res.userdetails.userID);
          localStorage.setItem('token',res.userdetails.token);
          localStorage.setItem('firstName',res.userdetails.firstName);
          localStorage.setItem('role',res.userdetails.role.toLowerCase().trim());
          localStorage.setItem('lastName',res.userdetails.lastName);
          localStorage.setItem('email',res.userdetails.email);

          this.storageService.set('user',res['userdetails']);
          this.submitted = false;
          this.loginForm.reset();
          this.signalRService.startConnection();
          this.signalRService.getExistingNotification();
          this.signalRService.bindNotification();
          if (res.userdetails.role.toLowerCase().trim() === 'no role assigned to user!') {
            localStorage.setItem('invitedCaregiver',res.isFromInvitation);
            this.route.navigate(['/role']);
          } else {
            localStorage.setItem('menulist', res.menulist);
            localStorage.removeItem('invitedCaregiver');
            this.commonService.setModules(res.menulist);

            this.redirectUser();
          }
        }else{
          this.toastr.error(res['message']);
          this.loginForm.reset();
        }
      });
    }else{
      return;
    }
  }

  redirectUser(){

    if (localStorage.getItem('role') === 'caregiver') {
			this.route.navigate([`/care-givers/profile/${localStorage.getItem('userId')}`]);
		}
		else if (localStorage.getItem('role') === 'carerecipient') {
			this.route.navigate([`/care-recipient/profile/${localStorage.getItem('userId')}`]);
		}
		else if (localStorage.getItem('role') === 'healthcareprovider') {
			this.route.navigate([`/healthcare-provider/profile/${localStorage.getItem('userId')}`]);
		}
    else if (localStorage.getItem('role') === 'admin') {
			this.route.navigate(['/dashboard']);
		}
    else{

    }

  }

  onPassword() {
    this.fieldTextType = !this.fieldTextType;
  }
}
