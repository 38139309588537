import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import {AuthGuard, LoginGuard, AdminGuard, AccessGuard} from '@/shared';
import {CommonService, InterceptService} from '@/shared/services';
import {ToastrModule} from 'ngx-toastr';
import { AccessDeniedRoutingModule } from './access-denied/access-denied-routing.module';
import { AccessDeniedModule } from './access-denied/access-denied.module';
import {NgxMaskModule} from 'ngx-mask';
import {NgxSpinnerModule} from 'ngx-spinner';
import {StorageService} from '@/shared/services/storage.service';
import { ReactiveFormsModule } from '@angular/forms';
import { UnauthorizedAccessComponent } from './static-pages/unauthorized-access/unauthorized-access.component';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    AccessDeniedRoutingModule,
    AccessDeniedModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDU5gXg1T4iJDTy056PgPCmvkN_06MmiHE',
      libraries: ['places']
    })
  ],
  declarations: [AppComponent, UnauthorizedAccessComponent],
  providers: [AuthGuard,LoginGuard,AdminGuard,AccessGuard,CommonService,StorageService,InterceptService,{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptService,
    multi: true
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
