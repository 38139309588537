import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from '@/shared/services';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router, private commonService: CommonService, private toastr : ToastrService) {}

  canActivate() {
    if( localStorage.getItem('isLoggedIn') === 'true' ) {
      return true;
    }
    this.storageService.clearStorage();
    this.router.navigate(['/login']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return true;
  }
}
