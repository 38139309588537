import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[medicareNumberMask]'
})
export class MedicareNumberMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }
  

  onInputChange(event, backspace) {
    if (event) {
      let medicareNumber = event.replace(/\D/g, '');
      if (backspace && medicareNumber.length <= 7) {
        medicareNumber = medicareNumber.substring(0, medicareNumber.length - 1);
      }
      if (medicareNumber.length === 0) {
        medicareNumber = '';
      } else if (medicareNumber.length <= 1) {
        medicareNumber = medicareNumber.replace(/^(\d{0,1})/, '$1-');
      } else if (medicareNumber.length <= 4) {
        medicareNumber = medicareNumber.replace(/^(\d{0,1})(\d{0,3})/, '$1-$2-');
      } else if(medicareNumber.length <= 7){
        medicareNumber = medicareNumber.substring(0,10);
        medicareNumber = medicareNumber.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/,'$1-$2-$3');
      }
      else {
        medicareNumber = medicareNumber.substring(0,11);
        medicareNumber = medicareNumber.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/,'$1-$2-$3-$4');
      }

      if (medicareNumber === '') {
        this.ngControl.control.patchValue('');
      } else {
        this.ngControl.valueAccessor.writeValue(medicareNumber);
      }

    }
    
  }
}
