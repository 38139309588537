import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDateTime'
})

@Injectable({
  providedIn: 'root'
})

export class LocalDateTimePipe implements PipeTransform {

  constructor() {}

  transform(value: any, args?: any): any {
    if (value) {
      value = `${value}.000Z`;
      return new Date(value).toString();
    }
  }
}
