import { Injectable} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import domtoimage from 'dom-to-image';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public currentModules: Observable<any>;
  private currentModuleSubject: BehaviorSubject<any>;

  notificationCount = new Subject<number>();
  resetQuestionnareTable = new Subject<Boolean>();
  reloadQuestionnareTable = new Subject<Boolean>();
  notifications = new Subject<any>();
  changeSideBar = new Subject<any>();
  changeHeaderBar = new Subject<any>();
  addressPattern = "^[A-Za-z0-9'\\.\\-\\s\\,]*$";

  constructor() {

    this.currentModuleSubject = new BehaviorSubject<any>({});
    this.currentModules = this.currentModuleSubject.asObservable();
    this.setModules(localStorage.getItem('menulist'));
  }

  downloadPDF(filename: string, htmlContainer: any, config?: any) {

    // To resolve pixelate image issue pass obj object
    const scale = 2;
    let obj = {
      height: htmlContainer.offsetHeight * scale,
      width: htmlContainer.offsetWidth * scale,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: htmlContainer.offsetWidth + "px",
        height: htmlContainer.offsetHeight + "px"
      }
    };

    return domtoimage.toPng(htmlContainer, obj)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;

        const pdf = new jspdf(config?.orientation || 'p',  config?.unit || 'mm', config?.size || 'a4');

        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(img, 'PNG', 0, 0, width, height);
        pdf.save(`${filename}.pdf`);
      });
  }

  scalePixel(htmlContainer){
    const scale = 2;
    return {
      height: htmlContainer.offsetHeight * scale,
      width: htmlContainer.offsetWidth * scale,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: htmlContainer.offsetWidth + "px",
        height: htmlContainer.offsetHeight + "px"
      }
    };
  }

  downloadPDF2(filename: string, htmlContainer: any, htmlContainer2: any, config?: any) {

    // To resolve pixelate image issue pass obj object
    const scale = 2;
    let obj = this.scalePixel(htmlContainer);

    return domtoimage.toPng(htmlContainer, obj)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;

        const pdf = new jspdf(config?.orientation || 'p',  config?.unit || 'mm', config?.size || 'a4');

        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(img, 'PNG', 0, 0, width, height);

        pdf.addPage();

        let obj2 = this.scalePixel(htmlContainer2);
        return domtoimage.toPng(htmlContainer2, obj2)
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            pdf.addImage(img, 'PNG', 0, 0, width, height);
            pdf.save(`${filename}.pdf`);

          });
      });
  }

  getPDF(htmlContainer){

    // const scale = 2;
   // let obj = this.scalePixel(htmlContainer);

   /* html2canvas(htmlContainer, {
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    windowWidth: document.documentElement.offsetWidth,
    windowHeight: document.documentElement.offsetHeight
  }).then(canvas => {
    let title = [];

    const contentDataURL = canvas.toDataURL('image/png');
    var pageWidth = 88.9;
    var pageHeight = 209.55;

    var imgHeight = canvas.height * pageWidth / canvas.width;
    var heightLeft = imgHeight;

    let pdf = new jspdf('p', 'mm', [88.9, 209.55]); // [88.9, 209.55]
    var position = 0;

    pdf.internal.scaleFactor = 2.24;
    pdf.addImage(contentDataURL, 'PNG', 0, position, pageWidth, imgHeight,undefined,'FAST');
    heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.internal.scaleFactor = 2.24;
        pdf.addImage(contentDataURL, 'PNG', 0, position, pageWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('testpdf' + '.pdf');

    });  */


    html2canvas(htmlContainer, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight
    });


    html2canvas(htmlContainer).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        // Few necessary setting options 88.9, 209.55
        const doc = new jspdf('p', 'mm', [88.9, 209.55]);

        const imgWidth = doc.internal.pageSize.getWidth();

        console.log(imgWidth);

        const pageHeight = 209.55;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;


        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        console.log(imgWidth, canvas.width, height, canvas.height);

        let heightLeft = imgHeight;
        let position = 0;

        doc.internal.scaleFactor = 2.24;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.internal.scaleFactor = 2.24;
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        // Generated PDF
        doc.save('small' + '.pdf');
    });

	};

  public downloadPDF3(filename: string, htmlContainer: any, config?: any)
  {
    html2canvas(htmlContainer).then(canvas => {
      // Few necessary setting options
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      let position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }

  /*Used to update sidebar */
  updateNotificationCount(data){ //type : string , data: any
    this.notificationCount.next(data);
  }

  /*Used to update sidebar */
  resetQuestionnare(){ //type : string , data: any
    this.resetQuestionnareTable.next(true);
  }

  reloadQuestionnare(data: any){ //type : string , data: any
    this.reloadQuestionnareTable.next(data);
  }

  /*Used to update sidebar */
  getNotifications(data){ //type : string , data: any
    this.notifications.next(data);
  }

  /*Used to update sidebar */
  passToSideBar(data){ //type : string , data: any
    this.changeSideBar.next(data);
  }

  /*Used to update header */
  passToHeaderBar(data){ //type : string , data: any
    this.changeHeaderBar.next(data);
  }

  setModules(modules: any) {
    this.currentModuleSubject.next(modules);
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    }
  }
  public setScope(type,data: any): void {
    window.localStorage.setItem(type,data);
  }

  public getScope(type) {
    return window.localStorage.getItem(type);
  }

  public noWhitespaceValidator(control: FormControl) {

    let isWhitespace = (control.value || '').trim().length === 0;
    ////console.log(isWhitespace);
    let isValid = !isWhitespace;
    return isValid ? null : {'required': true}
  }

  formatPhoneNumber(phoneNumberString) {
    const match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      //return this.newFormatedNumber = match[1] + '-' + match[2] + '-' + match[3]
    }
  }

  deformatPhoneNumber(phoneNumberString) {
    const match = phoneNumberString.match(/^(\d{3})-(\d{3})-(\d{4})$/);
    if (match) {
      //return this.newFormatedNumber = match[1] + match[2] + match[3]
    }
  }

  formatDate(date, obj=null) {
    var date = date.toString().substring(0, 10).split('/');
    if (obj) {
      const dateObj = {year: parseInt(date[0]), month: parseInt(date[1]), day: parseInt(date[2])};
      return dateObj;
    }
    else {
      date = date[1] + '-' + date[2] + '-' + date[0];
      return date;
    }
  }

  diffYears(dt2, dt1)
  {
    let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    return Math.abs(Math.round(diff/365.25));

  }

  // Get Score
  getScoreColor(score){
    let color= '';
    if (score>= 75){
      return color = 'green'
    }
    else if (score > 50 && score < 74 ){
      return color = 'grey'
    }
    else if (score > 30 && score < 49 ){
      return color = 'yellow'
    }
    else if (score < 30 ){
      return color = 'red'
    }
    else{
      return color= 'grey'
    }
  }

  updateAge(form : any, event: any){
    form.patchValue({
      age: (this.diffYears(new Date(event.target.value), new Date()) - 1) + ' years old',
    });
  }

  averageGeolocation(coords) {
    if (coords.length === 1) {
      return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
      let latitude = coord.lat * Math.PI / 180;
      let longitude = coord.lng * Math.PI / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }

    let total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      latitude: centralLatitude * 180 / Math.PI,
      longitude: centralLongitude * 180 / Math.PI
    };
  }
  /*getFormErrors(){
    Object.keys(this.editAgencyForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.editAgencyForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }*/
}
