
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { MarkAsteriskDirective } from './directives/mark-asterisk.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LocalDateTimePipe } from './pipes/localDateTimePipe';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { SsnNumberMaskDirective } from './directives/ssn-number-mask.directive';
import { dateMaskDirective } from './directives/date-mask.directive';
import { MedicareNumberMaskDirective } from './directives/medicare-number-mask.directive';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
// import { CKEditorModule } from '@ckeditor/ckeditor4-angular';
// import { CKEditorModule } from 'ckeditor4-angular';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DomSanitizedPipe } from './pipes/domSanitizePipe';
import { CKEditorModule } from 'ckeditor4-angular';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GooglemapsComponent } from '@/modals/googlemaps/googlemaps.component';
import { AgmCoreModule } from '@agm/core';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';

@NgModule({
  declarations: [
    RichTextEditorComponent,
    LoaderComponent,
    ConfirmationDialogComponent,
    PhoneMaskDirective,
    OnlynumberDirective,
    MarkAsteriskDirective,
    LocalDateTimePipe,
    SsnNumberMaskDirective,
    dateMaskDirective,
    MedicareNumberMaskDirective,
    RichTextEditorComponent,
    DomSanitizedPipe,
    GooglemapsComponent

  ],
  imports: [
   // CKEditorModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatInputModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    GooglePlaceModule,
    AgmCoreModule,
    DropdownModule,
    MultiSelectModule


  ],
  exports: [
    CKEditorModule,
    RichTextEditorComponent,
    MatNativeDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    PhoneMaskDirective,
    SsnNumberMaskDirective,
    dateMaskDirective,
    MedicareNumberMaskDirective,
    OnlynumberDirective,
    MarkAsteriskDirective,
    LocalDateTimePipe,
    DomSanitizedPipe
  ]
})
export class SharedModule { }
