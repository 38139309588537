import { Injectable, ViewChild } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import { CommonService } from './common.service';
import { ApiService } from '@/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class SignalRService {
	Reason: any;
	private hubConnection: HubConnection;
	count: number = 0;
	user;
	modalRef: NgbModalRef;
	constructor(
		private storageService: StorageService,
		public router: Router,
		private commonService: CommonService,
		private apiService: ApiService,
		private toastr: ToastrService,
		private modalService: NgbModal) {
		if (localStorage.getItem('isLoggedIn') === 'true') {
			this.startConnection();
			this.getExistingNotification();
			this.bindNotification();
			this.commonService.notificationCount.subscribe((data) => {
				this.count = data;
			});
		}

	}

	/* this method is to connect signalR hub */

	public startConnection = () => {
		this.hubConnection = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Error)
			.withUrl("http://3.7.81.188:5001/notificationHub", {
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets
			})
			.withAutomaticReconnect()
			.build();

		this.hubConnection.start().then(() => {
			console.log("signalR started");
		}).catch(err => {
			console.log('Error while starting connection: ' + err)
		});

		this.hubConnection.on('error', function (err) {
			console.log(err);
		})

	};

	bindNotification() {
		this.onDetailedReportRequest();
		this.onDetailedReportCreated();
		this.onQuestionnaireSubmitted();
		this.onAutoLogOut();
	}

	getExistingNotification() {
		this.apiService.postRequest('notification/list', {}).subscribe(res => {
			if (res.status) {
				let unreadNotificationCount = 0;
				res.data.forEach(element => {
					if (!element.isRead) {
						unreadNotificationCount = unreadNotificationCount + 1;
					}
				});
				this.commonService.getNotifications(res.data);
				this.commonService.updateNotificationCount(unreadNotificationCount);
			} else {
				this.commonService.updateNotificationCount(0);
				this.commonService.getNotifications([]);
			}
		});
	}


	public onDetailedReportRequest = () => {
		this.hubConnection.on('OnDetailedReportRequest', (title: string, body: string, type: string, extraDataJson: string) => {
			let item = JSON.parse(extraDataJson);
			if (item.ToUserId == localStorage.getItem('userId')) {
				this.showNotification(extraDataJson, type, title, 1);
			}
		});
	}

	public onDetailedReportCreated = () => {
		this.hubConnection.on('OnDetailedReportCreated', (title: string, body: string, type: string, extraDataJson: string) => {
			let item = JSON.parse(extraDataJson);
			if (item.ToUserId == localStorage.getItem('userId')) {
				this.showNotification(extraDataJson, type, title, 1);
				this.commonService.resetQuestionnare();
			}
		});
	}

	public onAutoLogOut = () => {
		this.hubConnection.on('OnAutoLogOut', (title: string, body: string, type: string, extraDataJson: string) => {
			let item = JSON.parse(extraDataJson);
			if (item.ToUserId == localStorage.getItem('userId')) {
				this.apiService.getRequest('Auth/logout').subscribe(res => {
					if (res.status == true) {
						this.storageService.clearStorage();
						this.closeConnection();
						this.router.navigate(['/login']);
					} else {
						this.toastr.error(res['message']);
					}
				});
			}
		});
	}

	public onQuestionnaireSubmitted = () => {
		this.hubConnection.on('OnQuestionnaireSubmitted', (title: string, body: string, type: string, extraDataJson: string) => {
			let item = JSON.parse(extraDataJson);
			if (item.ToUserId == localStorage.getItem('userId')) {
				this.showNotification(extraDataJson, type, title, 1);
			}
		});
	}


	showNotification(extraDataJson, type, message, notifyType) {
		let item = JSON.parse(extraDataJson);
		item.type = type;
		item.toUserId = item.ToUserId;
		item.notificationId = item.NotificationId;

		item.extraDataJson = extraDataJson;
		this.count += 1;
		this.commonService.updateNotificationCount(this.count);
		this.getExistingNotification();

		if (notifyType) {
			this.toastr.success(item.Message, message, {
				timeOut: 10000,
			}).onTap
				.pipe(take(1))
				.subscribe(() => {
					this.updateNotification(item)
				});
		} else {
			this.toastr.error(item.Message, message, {
				timeOut: 10000,
			}).onTap
				.pipe(take(1))
				.subscribe(() => {
					this.updateNotification(item)
				});
		}
	}

	updateNotification(item, obj = null) {
		this.apiService.deleteRequest('notification/delete', { "notificationId": item.notificationId, "toUserId": item.toUserId }).subscribe((res) => {
			if (res['status']) {
				obj.close();
				this.count > 0 ? this.count -= 1 : 0;
				this.commonService.updateNotificationCount(this.count);
				this.getExistingNotification();
				let extraData = JSON.parse(item.extraDataJson);

				if (item.type == 'detailed-report-request') {
					this.router.navigate(['/manage-reports']);
				}

				if (item.type == 'carerecipient-questionnaire-submitted') {
					this.router.navigate(['/dashboard']);
				}
			}
		});
	}

	closeConnection() {
		this.hubConnection.stop();
	}
}
