import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {

  public Editor = DecoupledEditor;
  @Input() control: FormControl;


  /*  public config = {
     removePlugins: ['CKFinder','EasyImage','Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload','MediaEmbed', 'FontSize', 'Heading', 'FontFamily', 'Link'] //if you want to remove any plugin
   }; */


  // For ckeditor 4
  public config = {
    toolbar: [
      ['Bold', 'Italic', 'Strike'],
      ['NumberedList', 'BulletedList', 'Table'],
      ['Undo', 'Redo'],
    ],
    versionCheck:false,
    extraPlugins:
      "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo,wsc,wysiwygarea"
  }



  constructor() { }

  ngOnInit(): void {

  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );

    editor.model.schema.on('checkChild', (evt, args) => {
      const context = args[0];
      const childDefinition = args[1];

      if (context.endsWith('tableCell') && childDefinition && childDefinition.name == 'table') {
        // Prevent next listeners from being called.
        evt.stop();
        // Set the checkChild()'s return value.
        evt.return = true;
      }
    }, { priority: 'highest' });
  }

}
