import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {slideToTop} from '@/shared/animations/router.animations';
import {ApiService} from '@/shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {Route, Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  submitted: boolean = false;
  forgotForm = this.formBuilder.group({
    email: ['',
      [
        Validators.required,
        Validators.email
      ]
    ]
  });
  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router

  ) {
    this.title.setTitle('Compass Care | Forgot Password')
  }

  ngOnInit(): void {
  }

  forgotPass(){
    this.submitted = true;
    //this.router.navigate(['reset-password']);
    if(this.forgotForm.valid){
      this.apiService.postRequest('Auth/password/forgot',  this.forgotForm.value).subscribe(res =>{
        if(res['status']){
          this.toastr.success(res.message);
          this.router.navigate(['login']);
        }
      })
    }
  }

}
