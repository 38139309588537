import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AccessGuard implements CanActivate {

    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        
        let user = localStorage.getItem('isLoggedIn');

        if (user) {

            let role = localStorage.getItem('role');

            if(route.data.roles && route.data.roles.indexOf(role) === -1){
                // role not authorised so redirect to 403 page
                this.router.navigate(['/unauthorized-access']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // Unauthorise
        this.router.navigate(['/login']);
        return false;
    }
}
